<template>
  <div id="registercomponent">
    <MetaboliteBrowseCompnentSearch :headT='head'></MetaboliteBrowseCompnentSearch>
    <div style="min-height:575px">
      <el-row>
        <el-col :span="8"></el-col>
        <el-col :span="8">
          <div class="grid-content">
            <div class="slider-demo-block" style="width:400px">
              <h4>User Name</h4>
              <el-input v-model="item.username" placeholder="Please input name">
              </el-input>
              <h4>Email</h4>
              <el-input v-model="item.email" placeholder="Please input email">
              </el-input>
              <h4>Password</h4>

              <el-input v-model="item.password" placeholder="Please input password" :show-password="true">
              </el-input>
              <h4>Confirm Password</h4>

              <el-input v-model="item.passwordconfirm" placeholder="Please input password" :show-password="true">
              </el-input>

              <el-button type="primary" :icon="User" :loading="loading" @click="register"
                style="margin: 30px 0px 15px 0px">register
                <User style="width:12; height:12" />
              </el-button>
              <!-- <el-slider v-model="intervel.left" show-input :max="chromosomeSelected.length"> </el-slider> -->
              <!-- <el-slider v-model="intervel.right" show-input :max="chromosomeSelected.length"> </el-slider> -->
            </div>
          </div>
        </el-col>
        <el-col :span="8"></el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
  import request from '@/network/request'
  import MetaboliteBrowseCompnentSearch from "@/components/Browse/MetaboliteBrowseCompnentSearch.vue"
  import { Edit, Upload, Picture, Search, UploadFilled, Histogram, User } from '@element-plus/icons-vue'
  import { ElMessage } from 'element-plus'
  export default {
    name: 'RegisterComponent',
    data () {
      return {
        head: "",
        item: {},
        registerResponse: ""
      }
    },
    methods: {
      register () {
        var query_params = { "email": this.item.email, "password": this.item.password, "username": this.item.username }
        request({
          url: 'register/',
          params: query_params,
          method: 'post'
        }).then(res => {
          this.registerResponse = res
          if (res == "register successfully!") {
            ElMessage({
              message: 'Congrats, register successfully. Please go to the e-mail to activate the account',
              type: 'success',
              showClose: true,
              duration: 0,
            })
          } else if (res == "The username has been registered!") {
            ElMessage({
              message: 'Sorry, The username has been registered!',
              type: 'error',
            })
          } else if (res == "The email has been registered!") {
            ElMessage({
              message: 'Sorry, The e-mail has been registered!',
              type: 'error',
            })
          }
        }).catch(err => {
          console.log(err);
        });
      }
    },
    components: {
      MetaboliteBrowseCompnentSearch,
      Edit,
      Upload,
      Picture,
      Search,
      UploadFilled,
      Histogram,
      User,
    },
    created () {

    },
    mounted () {
      window.vue = this
    },
    updated () {

    },

    watch: {
    }
  }
</script>

<style scoped lang="scss">
  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    /*    max-height: 1000px;
    overflow-y: scroll;*/
  }

  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .el-table {
    cursor: pointer;
  }
</style>